:root {
  --primary-color: #232327;
  --secondary-color: #727378;
  --black-color: #000;
  --white-color: #fff;
  --chat-bg: #f8edec;
  --bit-emoji: #161617;
  --helper-desc: #919191;
  --dark-grey: #474b64;
  --red-color: red;
  --mid-grey: #646464;
  --notification-bg: #f8eeec;
  --light-grey: #787878;
  --text-placeholder: #9b9b9b;
  --input-text: #474b64;
  --profile-item: #514949;
  --upload-cover-bg: #d9d9d9;
  --progile-bg: #f2f2f2;
  --dropdown-color: #6f6f6f;
  --profile-card: #f4f4f4;
  --feeds-desc: #747474;
  --profile-icon: #d5d5d5;
  --text-input: #f5f5f5;
  --chat-notification: #78e378;
  --main-color: #ff3d06;
  --private-bg: #f8f8fa;
  --dropdown-investment: #f4f5f6;
  --active-bg: #097c30;
  --drop-desc: #808080;
  --secondary-red: #ff3901;
  --startup-border: #eaeaea;
  --border-color: #e3e4e5;
  --category-border: #ddd;
  --category-bg: #f8f8f8;
  --bg-input: #fcfcfc;
  --bg-platform: rgba(183, 233, 246, 0.25);
  --bg-transform: #fcfcfc;
  --bold-text: #232327;
  --accent-text: #d8dbe9;
  --eye-color: #c8c8c8;
  --time-bg: #f6f9fd;
  --sort-filter: #d3d3d3;
  --bg-tooltip: #f9f8f8;
  --image-upload-border: rgba(255, 67, 14, 0.26);
  --box-shadow: 0px 0px 45px 0px rgba(149, 149, 149, 0.12);
  --header-color: #d3d3d3;
  --filter-background: #fafafa;
  --arow-color: #333;
  --dashboard-icon-color: #6b788e;
  --owner-color: #0e1435;
  --sidbar-item-color: #f7e8ef;
  --sidebar-simple-text: #091e42;
  --card-border: #ebebeb;
  --table-text: #242f48;
  --root-border: #d2d2d2;
  --investor-color: #afafaf;
  --table-row: #a0aec0;
  ---active-sidebar: #ff4814;
  --filter-placeholder: #718096;
  --table-background: #fff3f0;
  --gradient-color: linear-gradient(270deg, #ff3901d9 11.63%, #ff3901 28.91%, #ff3901d9 65.9%);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
  font-family: 'Poppins', sans-serif;
  overflow: auto !important;
}
html {
  scroll-behavior: smooth;
}

/* Css reset  */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
.wrapper-div {
  position: relative;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--primary-color);
  font-weight: bold;
  margin-bottom: 10px;
}
p {
  color: var(--white-color);
  font-size: 23px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 10px;
  margin-top: 8px;
}
img {
  max-width: 100%;
  height: auto;
}
a {
  display: inline-block;
  text-decoration: none !important;
  cursor: pointer;
}

.primary-btn {
  border-radius: 12px;
  background: linear-gradient(180deg, #ff3901 0%, rgba(255, 57, 1, 0.85) 100%);
  color: var(--white-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  border-radius: 12px;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 18px 0px;
  width: 100%;
  transition: all 0.3s ease;
}
.user-settings-btn {
  border-radius: 12px;
  background: linear-gradient(180deg, #ff3901 0%, rgba(255, 57, 1, 0.85) 100%);
  color: var(--white-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  border-radius: 12px;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 18px 0px;
  width: 15%;
  transition: all 0.3s ease;
}
.profile-exist-image {
  width: 25% !important;
}
.profile-exist {
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-disabled {
  border-radius: 12px;
  background: linear-gradient(180deg, #ff3901 0%, rgba(255, 57, 1, 0.85) 100%);
  color: var(--white-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  border-radius: 12px;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 18px 0px;
  width: 15%;
  transition: all 0.3s ease;
  opacity: 0.5;
}
.disabled-icon {
  color: var(--table-row) !important;
}
.disabled-password-input {
  background-color: #f5f5f5 !important;
  color: #808080 !important;
}
.profile-details-right {
  margin-top: 26px !important;
  width: 100%;
  max-width: 700px;
}
.primary-btn:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.main-heading {
  color: var(--primary-color);
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 10px;
}
.main-desc {
  color: var(--input-text);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}
.secondary-text {
  color: var(--main-color);
  font-size: 15.625px;
  font-weight: 400;
  line-height: 18px;
}
.form-label {
  color: var(--light-grey);
  font-size: 15.75px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 12px;
}
.form-input {
  border-radius: 12px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-input);
  color: var(--dark-grey);
  padding: 18px 15px;
  display: flex;
  z-index: 0 !important;
}
.PhoneInputCountrySelectArrow {
  margin: 0px 4px 0px 10px !important;
}
.PhoneInputInput {
  color: var(--dark-grey);
  border: none;
  outline: none;
  width: 100%;
  border-left: 1px solid var(--border-color) !important;
  background-color: transparent !important;
  padding-left: 9px;
}
.PhoneInput {
  width: 100%;
}
.PhoneInputCountrySelect {
  border: none;
  color: var(--dark-grey);
  outline: none;
  padding-right: 0px;
  font-size: 16px;
  background-color: transparent !important;
}
.form-input::placeholder {
  color: var(--dark-grey);
}
.form-input:focus {
  border: 1px solid var(--border-color);
  outline: none;
}
.search-input:focus {
  border: 1px solid var(--header-color);
  outline: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
.password-input:focus {
  outline: none !important;
  box-shadow: none;
}
.form-select-select {
  -webkit-appearance: none !important;
  appearance: none;
  background-image: url(/src/assets/svgs/arrow-down.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  background-size: 12px;
  z-index: 9;
}
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  appearance: none;
  opacity: 0;
  -webkit-appearance: none;
  cursor: pointer;
}
input[type='radio'] {
  accent-color: var(--active-bg);
}
.text-input {
  resize: none !important;
}
/* Sign in / Login Code */
.main-wrapper {
  min-height: 100vh;
}
.digital-banner {
  background: url('../../assets/images/digital-background.png') no-repeat;
  background-size: cover;
  min-height: 100vh;
}
.digital-platform {
  background-image: url('../../assets/images/platform-lines.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 540px;
  border-radius: 16px;
  max-width: 100%;
  max-width: 660px;
  width: 100%;
  border: 1px solid var(--white-color);
  background-color: var(--bg-platform);
  backdrop-filter: blur(13px);
  margin: 0px 40px;
  padding: 0px 60px 0px 60px;
}

.digital-desc {
  max-width: 450px;
  width: 100%;
  font-size: 21px;
  color: var(--white-color);
  margin: 15px 0px !important;
}
.digital-left-arrow {
  margin-left: -8px;
}
.digital-heading {
  color: var(--white-color);
  font-size: 58px;
  line-height: 60.99px;
  font-weight: 600;
}
.digital-text {
  color: var(--white-color);
  font-size: 58px;
  font-weight: 600;
}
.digital-arrow {
  font-size: 57px;
}
.right-arrow {
  font-size: 19px;
  color: var(--white-color);
  font-weight: bold;
}
.digital-investment {
  background: linear-gradient(180deg, #ff3901 0%, rgba(255, 57, 1, 0.85) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.user-transformation {
  background-color: var(--bg-transform);
  background-image: url('../../assets/images/background-overlay.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
  min-height: 100vh;
  height: 100%;
}
.user-satisfaction {
  background-position: center left;
}
.create-account {
  padding: 100px 80px 100px 80px;
}
.create-category {
  padding: 60px 80px 100px 80px;
}
.platform-logo {
  padding: 50px 0px 0px 80px;
}
.user-settings {
  min-height: auto;
  max-height: auto;
  margin-top: 20px;
  padding: 0px 10px !important;
}
.checkbox-box {
  border-radius: 5px;
  accent-color: var(--main-color) !important;
  border: 1px solid var(--accent-text);
  background: var(--white-color);
  width: 22px;
  height: 22px;
}
.checkbox-desc {
  cursor: pointer;
  color: var(--dark-grey);
  font-size: 16.375px;
  font-weight: 400;
  line-height: 20px;
}
.checkbox-desc b {
  color: var(--bold-text);
  font-weight: 500;
  text-decoration: underline;
  padding: 0px 4px;
  cursor: pointer;
}
.or-desc {
  color: var(--dark-grey);
  text-align: center;
  font-size: 16.625px;
  font-weight: 400;
}
.go-signin {
  color: var(--secondary-color);
  text-align: center;
  font-size: 16.625px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 33px !important;
}
.password-input {
  text-align: left;
  color: var(--dark-grey);
  width: calc(100% - 30px);
  border: none;
  background: transparent;
}
.password-input::placeholder {
  color: var(--dark-grey);
}
.eyeflash {
  border: none;
  outline: none;
  background-color: none;
  padding: 0 !important;
  margin: 0;
}
.eye-inner {
  color: var(--eye-color) !important;
  font-size: 18px;
}
.eyeflash:focus,
.eyeflash:active {
  border: none;
  outline: none;
  padding: 0;
}
.send-invite .form-input {
  flex: 1;
}
.send-invite .sendlink-btn {
  background: linear-gradient(180deg, #ff3901 0%, rgba(255, 57, 1, 0.85) 100%);
  text-align: center;
  color: var(--white-color);
  border-radius: 12px;
  font-size: 15px;
  font-weight: 400;
  border: none;
  outline: none;
  padding: 10px 30px;
  position: relative;
  left: -15px;
}
.verify-box {
  border-radius: 20px;
  background: linear-gradient(178deg, #fff 1.37%, #fff 85.59%, rgba(255, 255, 255, 0.79) 113.63%);
  box-shadow: var(--box-shadow);
  padding: 32px;
}
.create-new-password {
  padding: 50px 80px;
}
/* profile setup */
.category-text {
  color: var(--dark-grey);
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
}
.image-uploader {
  position: relative;
  max-width: 150px;
  width: 100%;
  height: 150px;
  border-radius: 50%;
  border: 2px solid var(--image-upload-border);
  background:
    lightgray 0px -14.874px / 100% 150.47% no-repeat,
    #d9d9d9;
  box-shadow: var(---box-shadow);
}

.upload-label {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  max-width: 151px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
}
#fileInput {
  display: none;
}
.preview-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}
.upload-label::before {
  content: url('/src/assets/svgs/plus arrow.svg');
  position: absolute;
  height: 40px;
  width: 40px;
  z-index: 1;
  top: 65%;
  right: -10px;
}
.skip-now {
  padding: 60px 80px 0px 80px;
}
.skip-category {
  padding: 60px 80px 0px 80px !important;
}
.info-account {
  padding: 40px 80px;
}
/* Tooltip ReUse */
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.profile-copy-icon {
  background: none;
  border: none;
  outline: none;
}
.tooltip {
  position: absolute;
  top: 140%;
  left: 40%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s,
    visibility 0.3s;
  z-index: 1;
  color: var(--dark-grey);
  font-size: 12px;
  font-weight: 500;
  padding: 8px 10px;
  border-radius: 10px;
  border: 1px solid var(--image-upload-border);
  background-color: var(--bg-tooltip);
  width: 120px;
  text-align: center !important;
}

.user-name-tooltip {
  top: 140%;
  left: 40%;
  transform: translateX(-50%);
  opacity: 1;
  visibility: visible;
  transition:
    opacity 0.3s,
    visibility 0.3s;
  z-index: 1;
  color: var(--dark-grey);
  font-size: 12px;
  font-weight: 500;
  padding: 8px 10px;
  border-radius: 10px;
  border: 1px solid var(--image-upload-border);
  background-color: var(--bg-tooltip);
  width: 120px;
  text-align: center !important;
}
.tooltip-container:hover .tooltip {
  opacity: 1;
  visibility: visible;
}
/* profile Select Field */
select {
  width: 100%;
}
.selected-options {
  display: flex;
  flex-wrap: wrap;
}
.selected-option {
  padding: 8px 10px;
  margin-right: 8px;
  margin-bottom: 5px;
  display: flex;
  opacity: 0.75;
  border-radius: 12px;
  background: linear-gradient(180deg, #ff3901 0%, rgba(255, 57, 1, 0.85) 100%);
  align-items: center;
  gap: 10px;
  color: var(--white-color);
}
.remove-option {
  cursor: pointer;
  color: var(--white-color);
}
.date-input {
  -webkit-appearance: none;
  appearance: none;
  background-image: url(/src/assets/svgs/calender.svg);
  background-repeat: no-repeat;
  cursor: pointer;
  background-position: calc(100% - 20px) center;
  background-size: 14px;
  width: 100%;
  z-index: 99;
}

/*Dashboard header */
.header {
  background: linear-gradient(180deg, #f4f6fc 0%, rgba(244, 246, 252, 0.97) 100%);
  height: 100px;
  padding: 0px 40px !important;
  position: fixed;
  inset: 0;
  z-index: 111;
  width: 100% !important;
}
.search-field {
  position: relative;
}
.search-input {
  border-radius: 12px;
  border: 1px solid var(--header-color);
  background: transparent;
  padding: 12px 36px 12px 10px;
  max-width: 340px;
  min-width: 340px;
  color: var(--dark-grey);
  font-size: 14px;
  font-weight: 400;
}
.search-input::placeholder {
  color: var(--header-color);
}
.ringer {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
.notification {
  border-radius: 12px;
  border: 1px solid var(--header-color);
  padding: 8.5px;
  max-width: 46px;
}
.notification .notification-bell {
  color: var(--header-color);
  font-size: 28px;
  cursor: pointer;
}

/* profile settings */
.profilep-img {
  position: relative;
  width: 100%;
  max-width: 59px;
  height: 59px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid var(--secondary-red);
  transition:
    width 0.3s ease,
    height 0.3s ease;
}
.profilep-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.owner-profile {
  flex: 1;
}
.owner-name {
  color: var(--owner-color);
  font-size: 15.75px;
  font-weight: 500;
}
.dropdown-toggle::after {
  display: none !important;
}
.settings-dropdown button {
  background: none !important;
  border: none !important;
  color: var(--investor-color) !important;
  padding: 0 !important;
}
.arrow-down {
  color: var(--arow-color);
}
/* dashboard-content */
.main-dashboard {
  min-height: 100vh;
  position: relative;
  padding-top: 100px;
  background-image: url('../../assets/images/background-overlay.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.main-dashboard {
  padding: 100px 40px 0px 40px !important;
}
.sidebar {
  width: 270px;
  height: calc(100vh - 100px);
  position: fixed;
  left: 0;
  top: 100px;
  border-right: 1px solid var(--eye-color);
  overflow-y: auto;
  z-index: 111;
  padding: 40px 30px 0px 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.main-content {
  padding: 10px;
  margin: 0 0 0 auto !important;
}
.dash-content {
  flex: 1;
  overflow: hidden !important;
}
.search-relative {
  max-width: 340px;
}
.footer-section {
  flex-shrink: 0;
}
.sidebar-item {
  display: flex;
  align-items: center;
  border-radius: 18px;
  gap: 12px;
  padding: 10px 18px;
  transition: all 0.3s ease !important;
  cursor: pointer;
}
.sidebar-item:hover .sidebar-icon,
.sidebar-item:hover span {
  color: var(--white-color);
}
.sidebar-item:hover {
  background: var(---active-sidebar);
  border-radius: 18px;
}
.sidebar-item.active {
  padding: 10px 18px;
  border-radius: 18px;
  transition: all 0.3s ease;
  background: var(---active-sidebar);
}
.sidebar-item.active span {
  color: var(--sidbar-item-color);
  font-size: 16px;
  font-weight: 500;
}
.sidebar-item.active .sidebar-icon {
  color: var(--white-color);
}
.sidebar-item span {
  color: var(--sidebar-simple-text);
  font-size: 15px;
  font-weight: 400;
}
.sidebar-icon {
  color: var(--sidebar-simple-text);
  font-size: 20px;
}
.trending {
  max-width: 140px;
  width: 100%;
}
.trending-profiles {
  height: auto;
  max-height: 300px;
  min-height: auto;
  overflow-y: scroll;
}
.trending-heading {
  color: var(--owner-color);
  font-size: 42px;
  font-weight: 600;
}
.trending-heading-small {
  font-size: 24px;
  font-weight: 600;
}
.slash-trending-small {
  font-size: 24px;
  font-weight: 600;
}
.slash-trending {
  font-size: 42px;
  font-weight: 600;
}
.following-filter:focus {
  border: 0.5px solid var(--sort-filter);
  outline: none;
}
.following-filter::placeholder {
  color: var(--filter-placeholder) !important;
  font-size: 16px;
  font-weight: 400;
}
.following-filter {
  border-radius: 12px;
  border: 0.5px solid var(--sort-filter);
  background: var(--filter-background);
  box-shadow: 0px 8px 32px 0px rgba(187, 187, 187, 0.24);
  padding: 12px 14px;
  appearance: none;
  max-width: 140px;
  -webkit-appearance: none !important;
  appearance: none;
  background-image: url(/src/assets/svgs/arrow-down.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 12px;
  z-index: 9;
  color: var(--filter-placeholder) !important;
}

.profile-card {
  border-radius: 16px;
  background: linear-gradient(178deg, #fff 1.37%, #fff 85.59%, rgba(255, 255, 255, 0.79) 113.63%);
  box-shadow: 0px 0px 45px 0px rgba(149, 149, 149, 0.12);
  min-height: 520px;
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.dashboard-main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.card-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
}

.item-name {
  color: var(--dark-grey);
  font-size: 14px;
  font-weight: 400;
}
.view-profile {
  overflow: hidden;
  border-radius: 16px 16px 0 0;
  width: 100%;
  min-height: 300px;
  max-height: 300px;
  transition: all 0.4s ease;
  overflow: hidden;
}
.feed-profile-img {
  width: 59px !important;
}
.profile-image {
  border-radius: 16px 16px 0 0;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  min-height: 300px;
  max-height: 300px;
  height: 100%;
  max-width: 100%;
}
.card-content .profile-heading {
  color: var(--black-color);
  font-size: 16px;
  font-weight: 600;
}
.profile-desc {
  color: var(--dark-grey);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--profile-card);
  flex-grow: 1;
}
.ratings-card {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.view-profile-content {
  flex-shrink: 0;
  margin-top: auto;
}
.ratings-row {
  row-gap: 10px;
  flex-wrap: wrap;
}
.view-button {
  border-radius: 12px;
  border: 1px solid var(--secondary-red);
  padding: 14px 0px;
  text-align: center;
  width: 100%;
  background: transparent;
  margin-top: 30px;
  color: var(--secondary-red);
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  transition: all 0.3s ease;
}
.investment-subheading {
  color: var(--owner-color) !important;
}
.view-button:hover {
  background: var(---active-sidebar);
  color: var(--white-color);
  border: 1px solid transparent;
}
.trending-button {
  max-width: 140px;
  padding: 14px 0px !important;
}
.trending-view-button {
  max-width: 80px;
  padding: 8px 0px !important;
  font-size: 14px;
}
.modal {
  background: rgba(0, 0, 0, 0.8);
}
.modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.modal-content {
  border-radius: 16px !important;
  background: linear-gradient(178deg, #fff 1.37%, #fff 85.59%, rgba(255, 255, 255, 0.79) 113.63%);
  box-shadow: 0px 0px 45px 0px rgba(149, 149, 149, 0.12);
  max-width: 450px;
}
.modal-heading {
  color: var(--owner-color);
  text-align: center;
  font-size: 26px;
  font-weight: 600;
}
.modal-header {
  padding: 0 !important;
  border-bottom: none !important;
}
.modal-heading .secondary-text {
  font-size: 26px;
  font-weight: 600;
}
.modal-desc {
  color: var(--owner-color);
  text-align: center;
  font-size: 18px;
  margin-top: 8px;
  font-weight: 300;
}
.modal-footer {
  border: none !important;
}
.modal-btn {
  padding: 14px 0px !important;
}
.modal-foot {
  padding: 10px 20px 24px 20px;
}
.cover-photo {
  margin-top: 100px !important;
}
.cover-img {
  max-width: 100%;
  width: 100%;
  border-radius: 10px !important;
  margin-top: 30px;
}
.cover-img img {
  min-height: 350px;
  border-radius: 10px !important;
  max-height: 350px;
  width: 100%;
  height: 350px;
  object-fit: cover;
  aspect-ratio: 1/2;
}
.cover-photo img {
  min-height: 350px;
  max-height: 350px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile-icons {
  width: 20px;
}
.uploader-cover-photo {
  margin-top: 30px;
  border-radius: 10px;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  min-height: 350px !important;
  max-height: 350px !important;
  background-color: linear-gradient(180deg, rgba(241, 56, 4, 0.31) 0%, rgba(241, 56, 4, 0) 100%) !important;
}
.profile-image-startup {
  position: relative;
  top: -50px;
  max-width: 184px;
  width: 100%;
  height: 184px;
  border-radius: 50%;
  border-left: 2px solid var(--image-upload-border);
  border-right: 2px solid var(--image-upload-border);
  border-bottom: 2px solid var(--image-upload-border);
  border-top: none;
  background:
    lightgray 0px -14.874px / 100% 150.47% no-repeat,
    #d9d9d9;
  box-shadow: var(---box-shadow);
  background-color: var(--progile-bg);
}
.status-profile {
  position: absolute;
  right: -1px;
  top: 30px;
  background: var(--bg-tooltip);
  width: 24.96px;
  height: 24.96px;
  z-index: 11;
  border: 4px solid var(--white-color);
  border-radius: 50%;
}
.profile-image-startup img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.status-profile.active {
  background: var(--active-bg);
}
.status-profile.away {
  background: var(--white-color);
  border: 4px solid var(--active-bg);
}
.status-profile.busy {
  background: #d32f2f;
}
.brand-details {
  gap: 40px;
}
.social-icons {
  cursor: pointer;
  color: var(--secondary-red);
  font-size: 20px;
  font-weight: bold;
}
.profile-heading {
  color: var(--owner-color);
  font-size: 24px;
  font-weight: 600;
}
.fa-badge {
  color: var(--active-bg);
  font-size: 18px;
}
.profile-status {
  color: var(--active-bg);
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  margin-top: 8px;
}
.profile-dettail {
  color: var(--owner-color);
  font-size: 15.75px;
  font-weight: 500;
}
.basic-dropdown {
  position: relative;
}
.header-notification {
  top: -19px !important;
  right: -20px !important;
  width: 24px !important;
  height: 24px !important;
}
.startup-feed {
  padding-left: 0 !important;
}
.profile-dropdown {
  width: 180px;
  transition: width 0.3s ease;
}
.profile-detail-status {
  color: #afafaf;
  font-size: 15.75px;
  font-weight: 400;
}
.text-underline {
  text-decoration: underline;
}
.text-center {
  text-align: center;
  color: var(--dark-grey);
  font-size: 20px;
  font-weight: 400;
}
.text-second {
  font-size: 20px;
  font-weight: 400;
}
.follow-btn {
  padding: 14px 0px;
  max-width: 230px;
}
.follow-button {
  padding: 14px 0px;
  max-width: 200px;
}
.follow-card {
  padding: 17px 20px;
  max-width: 60px;
}
.follow-icon {
  font-size: 20px;
}
.share-option {
  color: var(--secondary-red);
  font-size: 38px;
  cursor: pointer;
}
.share-options {
  color: var(--secondary-red);
  font-size: 24px !important;
  cursor: pointer !important;
}
.category-heading {
  color: var(--owner-color);
  font-size: 15.75px;
  font-weight: 500;
  line-height: 18px;
}
.interested-categories {
  margin-top: 36px;
  max-width: 470px;
  margin-left: auto;
}
.interested-profile {
  max-width: 456px;
}
.category-item {
  border-radius: 12px;
  border: 1px solid var(--category-border);
  color: var(--dark-grey);
  font-size: 15.625px;
  font-weight: 500;
  line-height: 18px;
  padding: 9px 16px;
  background: var(--category-bg);
}
.bordered-profiles .ratings-profile {
  margin-top: -10px !important;
  gap: 60px;
  padding-left: 34px !important;
}
.not-bordered {
  border-bottom: none !important;
}
.not-about-bordered {
  padding-bottom: 50px;
}
.not-private-input {
  max-height: 150px;
  min-height: 150px;
  height: 100%;
}
.bordered-profiles {
  padding-bottom: 34px;
  border-bottom: 1px solid var(--startup-border);
}
.about-heading {
  color: var(--owner-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
}
.about-desc {
  color: var(--dark-grey);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}
.about-bordered {
  padding-bottom: 34px;
  border-bottom: 1px solid var(--startup-border);
}
.joined-headings {
  color: var(--owner-color);
  font-size: 14.5px;
  font-weight: 600;
}
.joined-desc {
  color: var(--dark-grey);
  font-size: 14px;
  font-weight: 400;
}
.joined-bordered {
  padding-bottom: 34px;
  border-bottom: 1px solid var(--startup-border);
}
.profile-heading {
  color: var(--owner-color);
  font-size: 32px;
  font-weight: 600;
}
.form-address {
  min-height: 120px;
  height: 100%;
  max-height: 120px;
}
.browse-text {
  text-decoration: underline;
}
.tag-icon {
  max-width: 40px;
}
.multiple-column {
  column-gap: 15px;
}
.view-btn-hover {
  border-radius: 12px;
  border: 1px solid var(--white-color);
  padding: 8px 29px;
  background-color: transparent;
  color: var(--white-color);
  font-size: 16px;
  font-weight: 400;
  transition: all 0.3s ease;
}
.view-btn-hover:hover {
  background-color: var(--arow-color);
}
.feed-btn {
  padding: 14px 16px !important;
  max-width: 140px;
}
.transparent-btn {
  background: none;
  border: none;
  outline: none;
  color: var(--dark-grey);
  text-align: center;
  font-size: 16px;
  width: 124px;
  font-weight: 400;
}
.feeds-card {
  border-radius: 12px;
  border: 1px solid var(--card-border);
  padding: 16px;
}
.user-icon {
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
}
.user-icon img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}
.feed-heading {
  color: var(--owner-color);
  font-size: 20px;
  font-weight: 600;
}
.feed-desc {
  color: var(--dark-grey);
  font-size: 12px;
  font-weight: 400;
}
/* private box */
.private-box {
  padding: 0px 0px 0px 20px;
}
.private-heading {
  color: var(--owner-color);
  font-size: 20px;
  font-weight: 500;
}
.private-input {
  border-radius: 12px;
  border: 1px solid var(--card-border);
  background: var(--white-color);
  padding: 10px 16px !important;
  color: var(--dark-grey);
  width: 100%;
  max-height: 280px;
  min-height: 280px;
  height: 280px;
}
.private-input:focus {
  border: 1px solid var(--card-border);
  outline: none;
}
/* footer */
.angelflow-shadow {
  border-radius: 40px 40px 0px 0px;
  background: linear-gradient(180deg, #fff 0%, #fff 45.03%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 0px 0px 45px 0px rgba(149, 149, 149, 0.12);
  padding: 30px 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footer-desc {
  color: var(--owner-color);
  font-size: 15.5px;
  font-weight: 400;
}
.feed-type {
  background-color: var(--white-color);
  margin-top: 15px;
  height: auto;
  border-radius: 10px;
  margin-top: 17px;
  min-height: auto;
  overflow-y: scroll;
  min-height: 100vh;
  max-height: 100vh;
}
.post-image {
  height: 100%;
  max-width: 280px;
  min-width: 280px;
  width: 100%;
}
.post-image img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  max-height: 280px;
  object-fit: cover;
}
.feeds-post {
  padding: 24px 28px;
  border-bottom: 1px solid var(--border-color) !important;
}
.feeds-heading {
  color: var(--secondary-red);
  font-size: 18px;
  font-weight: 600;
}
.feeds-ago {
  color: var(--dark-grey);
  font-size: 12px;
  font-weight: 400;
}
.feed-time {
  max-width: 110px;
  width: 100%;
  background-color: var(--time-bg);
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 5px;
}
.feed-time p {
  color: var(--feeds-desc);
  font-size: 12px;
}
.feeds-desc {
  color: var(--feeds-desc);
  font-size: 12px;

  font-weight: 400;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border-color);
}
.read-btn {
  max-width: 140px;
  width: 100%;
  padding: 15px 15px;
  font-weight: 500 !important;
}
.feed-user {
  color: var(--feeds-desc);
  font-size: 13px;
  font-weight: 600;
}
.company-section {
  width: 100%;
  padding: 0px 10px 14px 10px !important;
  cursor: pointer;
}
.company-row {
  row-gap: 6px;
  border-bottom: 1px solid var(--border-color);
  margin-top: 23px !important;
  padding-bottom: 18px !important;
}
.company-section img {
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  min-height: 150px;
  max-height: 150px;
  height: 100%;
}
.notification-row {
  border-bottom: 1px solid var(--border-color);
}
.notification-box {
  background-color: var(--notification-bg);
  border-radius: 8px;
  padding: 38px 0px;
  justify-content: center;
  min-height: 110px;
  max-height: 110px;
  position: relative;
  display: flex;
  align-items: center;
}
.notification-indicator {
  position: absolute;
  top: -5px;
  right: -10px;
  width: 27px;
  padding: 5px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-red);
  border-radius: 50%;
  color: var(--white-color);
}
/* trending profiles */
.user-profile {
  min-height: 110px;
  max-height: 110px;
  max-width: 120px;
  width: 100%;
}
.user-profile img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.profiles-heading {
  color: var(--owner-color);
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}
.profiles-desc {
  color: var(--dark-grey);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.points-profile {
  color: var(--dark-grey);
  font-size: 14px;
  font-weight: 400;
}
.view-btn {
  text-decoration: underline;
  font-weight: 500;
  color: var(--secondary-red);
}

/* text editor css */
.text-input {
  border-radius: 8px;
  background: var(--text-input);
  min-height: 200px;
  max-height: 200px;
  height: 100%;
  width: 100%;
  border: none;
  padding: 15px 20px;
}
.text-input:focus {
  border: none;
  outline: none;
}
.text-input::placeholder {
  color: var(--text-placeholder);
  font-size: 16px;
  font-weight: 300;
}
.skip-now {
  padding: 60px 80px 0px 80px;
}
.post-btn {
  max-width: 130px;
  width: 100%;
  padding: 13px 10px;
}
.simple-btn {
  background: none;
  border: none;
  outline: none;
}
.bit-emoji {
  color: var(--bit-emoji);
  font-size: 30px;
  opacity: 0.5;
}
.dummy-user {
  border-radius: 50%;
}
.uploader-cover-photo {
  background: var(--upload-cover-bg);
  width: 100%;
  min-height: 330px;
  max-height: 300px;
}
/* edit profile css */
.custom-dropdown button {
  background: none !important;
  border: none !important;
}
.option-svg {
  color: var(--white-color);
  font-size: 30px;
  margin-top: 15px;
  cursor: pointer;
}
/* .cover-container {
  padding: 0px 40px !important;
} */
.desc-cover {
  color: var(--owner-color);
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
}
.profile-image-edit {
  position: relative;
  top: -50px;
  max-width: 184px;
  width: 100%;
  height: 184px;
  border-radius: 50%;
  border-left: 2px solid var(--image-upload-border);
  border-right: 2px solid var(--image-upload-border);
  border-bottom: 2px solid var(--image-upload-border);
  border-top: none;
  background:
    lightgray 0px -14.874px / 100% 150.47% no-repeat,
    #d9d9d9;
  box-shadow: var(---box-shadow);
  background-color: var(--progile-bg);
}
.drop-desc {
  color: var(--drop-desc);
  text-align: center;
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
}
.secondary-desc {
  font-size: 11px;
  font-weight: 500;
  text-decoration: underline;
}
.status-heading {
  color: var(--light-grey);
  font-size: 15.75px;
  font-weight: 500;
}
.set-status {
  color: var(--dark-grey);
  font-size: 16px;
  font-weight: 400;
}
.startup-points {
  row-gap: 30px;
  column-gap: 14px;
  border-bottom: 1px solid var(--border-color) !important;
}
.startup-points .col-lg-6 {
  width: 46.5% !important;
}
.startup-name .col-lg-6 {
  width: 50% !important;
}
.investor-column {
  column-gap: 20px;
}
.about-text {
  min-height: 340px;
  max-height: 340px;
  resize: none !important;
}
.about-company {
  min-height: 120px;
  max-height: 120px;
  resize: none !important;
}
textarea {
  resize: none !important;
}
.selected-category {
  max-width: 400px;
  width: 100%;
}
.profile-row {
  row-gap: 30px;
  column-gap: 40px;
}
.investment-row {
  row-gap: 20px;
}
.update-btn {
  max-width: 220px !important;
  width: 100%;
  padding: 18px 16px;
}
.profile-drag {
  border-radius: 12px;
  border: 1px solid var(--secondary-red);
  background: linear-gradient(0deg, #fff 0%, #fff 100%), #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  margin-top: 30px;
  max-width: 96%;
}
.update-button {
  max-width: 160px !important;
  width: 100%;
  padding: 14px 10px;
}
/* chat css */
.chat-content {
  background-color: var(--white-color);
}
.inner-chat-content {
  padding: 12px 16px;
}
.inner-chat-content.active {
  background-color: var(--text-input);
}
.inner-chat-content:hover {
  background-color: var(--text-input);
}

.note-text {
  background-color: #f0f0f0;
  color: #333;
  padding: 0px 8px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 11px;
  text-align: right;
  margin-left: auto;
  text-align: right;
  justify-content: end;
  display: flex;
  align-items: center;
}
.PhoneInputInput--focus {
  border: none !important;
}
.chat-user-img {
  max-width: 52px;
  width: 100%;
  min-height: 52px;
}
.chat-user-img img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.user-img-chats img {
  border-radius: 50%;
}
.chat-heading {
  color: var(--owner-color);
  font-size: 16px;
  margin-bottom: 0 !important;
  font-weight: 600;
  max-width: 200px;
  width: 100%;
}
.chat-time {
  color: var(--light-grey);
  font-size: 12px;
  line-height: normal;
  font-weight: 400;
}
.chat-desc {
  color: var(--light-grey);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.chat-notification {
  background-color: var(--chat-notification);
  width: 19px;
  height: 19px;
  color: var(--white-color);
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  border-radius: 50%;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* chat header */
.inbox-header {
  border-bottom: 1px solid var(--bg-input);
  background: var(--white-color);
  padding: 8px 24px 8px 16px;
}
.inbox-chating {
  background: var(--chat-bg);
  padding: 20px 26px;
  min-height: 635px;
  max-height: 635px;
}
.inner-inbox-chating {
  margin-bottom: 20px;
  height: 100%;
  max-height: 510px;
  min-height: 510px;
  overflow-y: scroll;
}
.inner-inbox-chating::-webkit-scrollbar {
  display: none;
}

.react-input-emoji--input {
  overflow-y: hidden;
}
.inner-inbox-chating ul {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.send-chat {
  background: var(--white-color) !important;
  border-radius: 12px;
  margin-right: 30px;
  color: var(--owner-color);
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  max-width: max-content;
}
.send-chat span {
  text-align: right !important;
}
.recive-chat {
  background: var(--secondary-red);
  border-radius: 12px;
  margin-left: 30px;
  color: var(--white-color);
  font-size: 16px;
  max-width: max-content;
  margin-left: auto;
  text-align: right;
  font-weight: 400;
}
.inner-inbox-chating ul li {
  font-style: normal;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 8px;
}
.chat-search-field {
  border-radius: 12px;
  background-color: var(--white-color);
  width: 100%;
  padding: 14px 16px;
}
.chat-search-field input {
  outline: none;
  border: none;
  color: var(--owner-color);
  font-size: 16px;
  font-weight: 400;
  background: transparent;
  width: 100%;
}
.chat-emoji {
  color: var(--light-grey);
  font-size: 26px;
  cursor: pointer;
}
.react-emoji {
  flex-direction: row-reverse;
}
.send-icon {
  color: var(--secondary-red);
  font-size: 27px;
  cursor: pointer;
}
.chat-feild {
  max-width: 90%;
  width: 100%;
}
.form-select-form {
  padding: 10px;
  flex-wrap: nowrap !important;
  position: static;
}
.form-select-form input {
  border: 1px solid var(--border-color);
}
.input-group-text {
  padding: 0 !important;
  border: none !important;
  background: none !important;
  color: var(--light-grey) !important;
  font-size: 15.75px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
}
/* investment-table */
.investment-table {
  font-family: 'Poppins', sans-serif !important;
  padding: 16px !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.css-13xy2my {
  background-color: transparent !important;
  background-color: inherit !important;
  color: none !important;
  border-radius: 4px;
  box-shadow: none !important;
  padding: 10px 0px 0px 0px !important;
}
.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none !important;
  border: none !important;
  background: transparent !important;
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 10px 0px 0px 0px !important;
}
.investment-table table {
  border-collapse: separate;
  background-color: transparent !important;
}
.investment-table .MuiTablePagination-root {
  background-color: none !important;
}
.css-rqglhn-MuiTable-root {
  font-family: 'Poppins', sans-serif !important;
}
.investment-table thead tr th {
  font-family: 'Poppins', sans-serif !important;
  border-bottom: none !important;
  color: var(--filter-placeholder);
  font-size: 14px;
  font-weight: 600;
  padding: 16px;
  text-align: center;
  vertical-align: middle;
}
.investment-table tbody tr td {
  font-family: 'Poppins', sans-serif !important;
  color: var(--table-text);
  font-size: 14px;
  font-weight: 500;
  padding: 20px 16px 20px 16px;
  text-align: center;
  vertical-align: middle;
}
.investment-table thead tr {
  border-radius: 8px;

  background-color: var(--table-background);
  border-radius: 8px !important;
}
.investment-table thead tr th:nth-child(1) {
  border-radius: 8px 0 0 8px;
}
.investment-table thead tr th:last-child {
  border-radius: 0px 8px 8px 0px;
}
.MuiTablePagination-root {
  margin-top: 10px !important;
}
.MuiTablePagination-selectLabel {
  margin-bottom: 0 !important;
  color: var(--owner-color);
  font-size: 14px !important;
  color: var(--filter-placeholder);
  font-family: Poppins !important;
  font-size: 14px;
  margin-right: 10px !important;
  font-weight: 400;
}
.MuiInputBase-root {
  border-radius: 6px;
  border: 1px solid var(--root-border);
  margin: 0 40px 0px 30px;
  font-family: Poppins !important;
}
.MuiTablePagination-displayedRows {
  color: var(--table-row);
  text-align: center;
  font-family: Poppins !important;
  font-size: 15px !important;
  font-weight: 400;
  margin-bottom: 0 !important;
}
/* Dropdown */
.table-dropdown {
  background: none !important;
  border: none !important;
  outline: none;
  padding: none;
}
.table-dropdown {
  border: none;
  background: none !important;
  cursor: pointer;
}
.three-dot {
  color: var(--table-text);
}
.investment-menu {
  border-radius: 10px;
  background: var(--white-color);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 8px !important;
}
.dropdown-item:active {
  background-color: var(--dropdown-investment) !important;
  color: var(--owner-color) !important;
}
.investment-menu .dropdown-item {
  background-color: var(--dropdown-investment);
  border-radius: 5px;
  display: flex;
  color: var(--owner-color);
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  padding: 8px;
  gap: 5px;
}
.investment-menu .dropdown-item:first-child {
  margin-bottom: 6px;
}
.del-icon {
  color: var(--secondary-red);
}
.investor-btn {
  max-width: 250px;
  width: 100%;
}
.edit-modal-investment .modal-content {
  max-width: 1280px;
  min-width: 1280px;
  width: 100%;
  padding: 16px !important;
}
.modal-update {
  padding: 10px 20px 24px 20px;
  max-width: 380px;
  margin-left: auto;
}
.dashboard-filter {
  max-width: 320px;
  width: 100%;
}
.verify-modal {
  min-height: 100vh !important;
}
.verify-modal .modal-content {
  padding: 60px 30px !important;
  max-width: 500px !important;
}
/* loader gif */
.loader-wrapper {
  position: relative;
  background-image: url('../../assets/images/loader-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.loader-container {
  min-height: 100vh;
  backdrop-filter: blur(5px);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}
.loader-container img {
  display: block;
  margin: 0 auto;
  box-shadow: 0px 4px 58.6px rgba(149, 149, 149, 0.25);
  border-radius: 50%;
}
.profile-img {
  max-height: 120px !important;
  min-height: 120px !important;
  height: 100%;
  border-radius: 5px;
  background: linear-gradient(141deg, #fff 22.44%, rgba(255, 255, 255, 0) 110.9%);
  box-shadow: 0px 0px 18px 0px rgba(218, 222, 232, 0.5);
  padding: 20px 14px;
  position: relative;
  max-width: 120px;
  width: 100%;
}
.profile-img-icon {
  max-width: 80px;
}
.profile-img-icon img {
  height: 100px;
  width: 100%;
}
.profile-fields {
  padding-left: 35px !important;
}
.profile-fields-feed {
  padding-left: 5px !important;
}
.cancel-img {
  position: absolute;
  top: -14px;
  right: -17px;
  background-color: var(--main-color);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.cancel-img-icon {
  color: var(--white-color);
  font-size: 19px;
  font-weight: bolder;
}
.profile-category {
  row-gap: 20px;
}
.notifiction-dropdown .dropdown-menu {
  width: 100%;
  min-width: 230px;
  transform: translate3d(-30px, 35px, 0px) !important;
}
.settings-dropdown .dropdown-menu {
  padding: 0 !important;
  border-radius: 8px !important;
  background: var(--white-color);
  box-shadow: 8px 12px 20px 0px rgba(0, 0, 0, 0.19);
  margin-top: 10px;
  transform: translate(0px, 26.25px) !important;
}
.profile-item {
  width: 100%;
  padding: 0px !important;
  border-bottom: 1px solid var(--startup-border) !important;
  border-radius: 8px !important;
}
.profile-item a {
  color: var(--profile-item);
  font-size: 14px;
  border-radius: 8px !important;
  padding: 8px 15px !important;
  font-weight: 400;
}
.user-notification {
  padding: 5px 10px;
  cursor: pointer;
}
.notfication-heading {
  color: var(--owner-color);
  font-size: 16px;
  font-weight: 500;
  margin: 0 !important;
}
.notification-desc {
  color: var(--light-grey);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.post-feeds {
  padding: 6px 5px 0px 0px;
}
.post-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.news-feed-desc {
  flex-grow: 1;
}
.feeds-content-button {
  flex-shrink: 0;
  margin-top: auto;
}
.profiles-content {
  display: flex;
  flex-direction: column;
}
.profiles-desc {
  flex-grow: 1;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  scrollbar-gutter: stable;
  max-height: 20px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: rgba(74, 75, 77, 0.1);
  border-radius: 10px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(---active-sidebar);
}
.custom-scrollbar::-webkit-scrollbar:horizontal {
  max-height: 5px;
}
.custom-scrollbar::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px;
  background-color: var(---active-sidebar);
  width: auto;
}
.chat-listing-default {
  background-color: var(--white-color);
  min-height: 711px;
  max-height: 711px;
}
.about-chat {
  background-color: var(--white-color);
  border-left: 1px solid rgba(188, 188, 188, 0.35);
  min-height: 711px;
  max-height: 711px;
}
.dash-chat-content {
  min-height: 80vh;
  height: 100%;
  margin: 0 auto;
}
.welcome-content {
  max-width: 350px;
  text-align: center;
  margin-top: 10px;
}
.welcome-content h1 {
  color: var(--owner-color);
  font-size: 32px;
  font-weight: 600;
  line-height: normal;
}
.welcome-content p:first-of-type {
  color: var(--owner-color);
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
}
.welcome-content p:last-of-type {
  color: var(--owner-color);
  font-size: 12px;
  font-weight: 300;
  line-height: normal;
}
.main-error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url('../../assets/images/background-overlay.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.menu-opener {
  display: none;
}
.overlay-styled {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s ease-in-out;
  overflow: auto;
}
.overlay-styled.is-active {
  display: block;
  opacity: 1;
  visibility: visible;
  overflow: hidden;
}
.edit-image {
  min-height: 350px !important;
  max-height: 350px !important;
  border-radius: 10px;
  width: 100%;
  object-fit: cover;
}
.chat-head {
  max-width: 320px;
  width: 100%;
}
.advanced-details {
  margin: 25px 0px !important;
  text-align: center !important;
  line-height: normal;
  padding-right: 15px;
}
.advanced-details .secondary-text {
  text-decoration: underline;
  cursor: pointer;
}
.switch-profile {
  display: none;
}
.sidebar .cross-icon {
  display: none !important;
}
.investor-link {
  width: 100%;
}
.news-feeds {
  min-height: auto;
  max-height: 360px;
  height: 100%;
  height: auto;
  overflow-y: scroll;
}
.feed-img {
  max-width: 380px;
}
.about-feed {
  min-height: 160px;
  max-height: 160px;
}
.text-image {
  color: var(--text-placeholder);
}
.visual-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
.public-private {
  margin: -30px 0px 26px 0px;
}
.private-checkbox {
  width: 21px;
  height: 22px;
}
.profile-input {
  padding: 9px 9px 9px 15px !important;
}
.wrapper-text {
  color: var(--light-grey);
  font-size: 15.75px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0px;
  flex: 1;
}
.handbook-select {
  cursor: pointer;
  color: var(--arow-color);
  font-size: 20px;
}
.select-form {
  width: 100%;
}
.form-label .aestaric {
  color: var(--red-color);
  margin-right: 5px;
  margin-left: 5px;
  top: 50%;
  transform: translateY(-50%);
}
.label-phone .aestaric {
  margin-left: 0px !important;
}
.startup-updates {
  margin-top: 36px !important;
}
.updated-buttons {
  margin-top: 33px !important;
}
.note-desc {
  color: var(--dark-grey);
  font-size: 14px;
  line-height: normal;
  margin-bottom: 10px !important;
}
.cover-label {
  width: 100%;
}
.cover-container {
  position: relative;
}
.custom-dropdown {
  position: absolute !important;
  right: 0;
}
.feeds-container {
  background-color: var(--white-color);
  padding: 16px 12px 10px 12px;
  border-radius: 0.8rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
}
.menu-header {
  max-width: 800px;
  width: 100%;
}
.btn-post {
  background-color: transparent;
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: 30px;
  display: flex;
  padding: 0px 16px;
  justify-content: flex-start;
  align-items: center;
  height: 52px;
  font-size: 13px;
  font-weight: 450;
  line-height: normal;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}
.btn-post:hover {
  background-color: #f3f3f3;
}
.profile-update-img {
  width: 100%;
  max-width: 54px;
  height: 54px;
  box-sizing: border-box;
  background-clip: content-box;
}
.media-btn {
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  padding: 14px 8px 14px 8px;
}
.media-btn:hover {
  background-color: #f3f3f3;
  border-radius: 8px;
}
.media-gallery {
  color: #378fe9;
  font-size: 20px;
  margin-bottom: 1px;
}
.event-gallery {
  color: #c37d16;
  font-size: 20px;
}
.article-gallery {
  color: #e06847;
  font-size: 20px;
  margin-bottom: 2px;
}
.modal-post {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.modal-post .modal-dialog {
  min-height: 50vh;
  padding-right: 0 !important;
}
.modal-post .modal-dialog .modal-content {
  max-width: 740px;
  width: 100%;
  min-width: 740px;
}
.modal-post .modal-dialog .modal-body {
  padding-right: 15px !important;
}
.cross-btn {
  background: transparent;
  border: none;
  outline: none;
  margin-right: 10px;
  padding: 0;
}
.cross-btn .cross-icon {
  font-size: 20px;
  font-weight: bold;
  color: var(--bold-text);
}
.modal-desc {
  font-size: 14px;
  font-weight: 400;
  color: var(--drop-desc);
  margin: 0 !important;
  line-height: normal;
}
.profile-owner {
  font-size: 20px;
}

.otp-button {
  max-width: 120px;
}

.otp-note {
  margin-left: 10px;
  color: var(--secondary-color);
  font-size: 13.625px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 18px !important;
}
.drag-img {
  max-width: 100%;
  width: 100%;
  min-height: 150px;
  height: 100%;
  object-fit: cover;
  max-height: 150px;
}
.update-pwd {
  margin-top: 26px;
  margin-bottom: 20px;
}
.column-pwd {
  padding-top: 38px !important;
}
/* Helper banner */
.helper-banner-bg {
  background-image: url('../../assets/images/helper-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  margin-top: 16px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}
.cross-helper {
  position: absolute;
  right: 12px;
  top: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 50%;
  background-color: var(--main-color);
}
.icon-ex {
  color: var(--white-color);
}
.helper-content {
  padding: 30px 0px 30px 40px;
}
.helper-content h1 {
  color: var(--main-color);
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  margin-bottom: 16px;
  max-width: 357px;
  width: 100%;
}
.helper-content p {
  color: var(--helper-desc);
  font-size: 14px;
  font-weight: 300;
  line-height: 23px;
  margin-bottom: 16px;
  max-width: 357px;
  width: 100%;
}
.explore-btn {
  padding: 10px 15px;
  color: var(--main-color);
  font-weight: 600;
  line-height: 30px;
  max-width: 180px;
  width: 100%;
}

.modal-backdrop {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-filter .modal.show .modal-dialog {
  overflow: hidden;
}
.modal-filter .modal-content {
  max-width: 750px;
  width: 100%;
}
.modal-filter .modal-dialog {
  max-width: 750px;
  margin: 30px auto;
  width: 100%;
  border-radius: 0 !important;
}
.modal-filter {
  z-index: 1130 !important;
  overflow: hidden !important;
}
.modal-filter .modal-body {
  padding: 5px 32px !important;
}
.modal {
  -webkit-overflow-scrolling: touch;
  overflow: hidden !important;
}
.feedback-btn {
  max-width: 160px;
  width: 100%;
}
.modal-filter .modal-header {
  padding: 32px 32px 0px !important;
}
.modal-filter .btn-close {
  position: absolute;
  right: 32px;
  top: 28px;
}
.modal-footer-feedback {
  padding: 10px 0px 30px;
}
.feedback-heading {
  color: var(--main-color);
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
}
.feedback-desc {
  color: var(--bit-emoji);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 30px;
}

/* Read more screen */
.updates-section {
  border-radius: 10px 10px 10px 10px;
  background: var(--white-color);
  min-height: 100vh;
  margin: 30px 0px 40px 0px;
}
.updates-image {
  width: 100%;
  max-width: 100%;
  padding: 0;
}
.updates-image img {
  max-width: 100%;
  width: 100%;
  max-height: 230px !important;
  min-height: 230px !important;
  height: auto;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}
.profile-updates {
  padding: 0px 28px;
  position: relative;
}
.profile-updates::after {
  content: '';
  position: absolute;
  width: 96.6%;
  height: 1px;
  background-color: var(--border-color);
  bottom: 20px;
}
.detailed-content {
  margin-top: -30px;
}
.updates-content {
  margin-top: -13px;
  padding: 10px 28px;
}
.detailed-content h2 {
  color: var(--owner-color);
  font-size: 24px;
  font-weight: 500;
  line-height: 18px;
}
.updates-detail-desc {
  color: var(--dark-grey);
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
}
.updates-content h1 {
  color: var(---active-sidebar);
  font-size: 32px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
}
.updates-content p {
  color: var(--dark-grey);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}
.detail-feed {
  max-width: 130px;
  height: 38px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0px 6px;
}
.detail-feed p {
  color: var(--feeds-desc);
  font-size: 13px;
  margin: 0 !important;
}
/* Media Quries for Mobile responsiveness */
@media screen and (max-width: 1680px) {
  .post-image {
    height: 100%;
    max-width: 260px;
    min-width: 260px;
    width: 100%;
  }
}
@media screen and (max-width: 1440px) {
  .digital-text {
    font-size: 48px;
  }
  .platform-logo {
    padding: 50px 0 0 40px;
  }
  .create-account {
    padding: 60px 40px;
  }
  .digital-desc {
    font-size: 16px;
  }
  .digital-platform {
    padding: 0 10px 0 60px;
    margin: 0px 30px;
  }
}
@media screen and (max-width: 1280px) {
  .main-heading {
    font-size: 34px;
  }
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 992px) {
  .sidebar .cross-icon {
    display: flex !important;
    justify-content: end !important;
    align-items: center !important;
  }
  .main-wrapper .digital-banner {
    display: none !important;
  }
  .dashboard .search-profile {
    display: none !important;
  }
  .menu-opener {
    display: block;
    cursor: pointer;
  }
  .sidebar {
    position: fixed;
    left: -300px;
    width: 230px;
    height: 100%;
    max-height: calc(100vh - 70px);
    transition:
      left 0.3s ease,
      opacity 0.3s ease;

    background-color: rgba(255, 255, 255, 0.9);
    z-index: 11;
    background-color: var(--white-color);
    top: 70px;
    padding: 18px 20px 0px 12px;
    gap: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 0px 33px 33px 0px;
    opacity: 0;
  }
  .sidebar.open {
    left: 0;
    top: 70px;
    opacity: 1;
  }
  .main-content {
    width: 100% !important;
  }
  .main-dashboard {
    padding: 40px 16px 0px 16px !important;
  }
  .trending-heading {
    font-size: 29px;
  }
  .header {
    height: 70px;
    padding: 0px 0px 0px 16px !important;
  }
  .header .logo img {
    max-width: 160px;
  }
  .opener {
    font-size: 24px;
    color: var(--owner-color);
  }
  .uploader-cover-photo {
    min-height: 250px !important;
    max-height: 250px !important;
  }
}
@media screen and (max-width: 768px) {
  .platform-logo {
    padding: 30px 0 0 20px;
  }
  .create-account {
    padding: 50px 20px 20px;
  }
  .skip-category {
    padding: 30px 20px 0px 20px !important;
  }
  .create-account .login-form {
    margin-top: 28px !important;
  }
  .main-heading {
    font-size: 25px;
  }
  .form-label {
    font-size: 14px;
  }
  .form-input {
    font-size: 14px;
    padding: 14px 15px;
    z-index: 0;
  }
  .primary-btn {
    font-size: 14px;
    padding: 13px 0px;
  }
  .right-arrow {
    font-size: 13px;
  }
  .create-account .or-desc {
    font-size: 13.625px;
    margin: 16px 0px !important;
    line-height: normal;
  }
  .go-signin {
    font-size: 14px;
    margin-top: 24px !important;
  }
  .secondary-text {
    font-size: 14px;
  }
  .social-links {
    gap: 20px;
  }
  .create-account .main-desc {
    margin-top: 10px !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
  }
  .checkbox-box {
    max-width: 20px;
    width: 100%;
    height: 20px;
  }
  .remember-text {
    width: 130px;
  }
  .send-invite {
    display: flex;
  }
  .send-invite .sendlink-btn {
    font-size: 13px;
    padding: 10px 0px;
    left: -15px;
    width: 140px;
  }
  .create-account .formlabel-input {
    margin-top: 16px !important;
  }
  .login-form .image-uploader {
    margin: 0px auto 10px auto !important;
  }
  .login-form .form-input-label {
    margin-bottom: 14px !important;
  }
  .select-country {
    font-size: 12px;
  }
  .login-form .go-profile {
    margin-top: 16px !important;
  }
  .user-img {
    max-width: 20px !important;
  }
  .login-form .profile-category {
    flex-direction: column !important;
    margin: 0px 0px !important;
    gap: 18px;
  }
  .category-text {
    font-size: 18px;
  }
  .skip-now {
    padding: 30px 20px 0px 20px;
  }
  .selected-option {
    font-size: 14px;
  }
  .info-account {
    padding: 20px 20px !important;
  }
  .checkbox-desc {
    font-size: 12px;
  }
  .trending-heading {
    font-size: 28px;
  }
  .titles-trending .dashboard-filter {
    flex-direction: column;
    max-width: 100px;
    width: 100%;
    gap: 8px !important;
  }
  .dashboard-filter .dashboard-filter {
    width: 100%;
  }
  .dashboard-filter .dashboard-filter .view-button {
    padding: 8px 0px !important;
  }

  .following-filter {
    font-size: 13px;
    padding: 8px 8px !important;
  }
  .trending-button {
    font-size: 13px;
    padding: 10px 0px;
  }
  .sidebar-item.active span {
    font-size: 14px;
  }
  .sidebar-item span {
    font-size: 13px;
  }
  .sidebar-icon {
    font-size: 16px;
  }
  .footer-desc {
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
  }
  .angelflow-shadow {
    padding: 16px 20px;
    flex-direction: column;
  }
  .angel-footer {
    display: flex;
    justify-content: center;
  }
  .modal-content {
    margin: 0px 16px;
  }
  .modal-heading {
    font-size: 20px;
  }
  .modal-heading .secondary-text {
    font-size: 20px;
  }
  .modal-desc {
    font-size: 14px;
    line-height: normal;
    margin-bottom: 0 !important;
  }
  .modal-title img {
    max-width: 120px;
  }
  .view-button,
  .modal-btn {
    padding: 12px 0px !important;
    font-size: 14px;
  }
  .modal-body {
    padding: 4px 10px !important;
  }
  .modal {
    padding-left: 0 !important;
  }
  .angel-logos {
    max-width: 180px;
  }
  .dash-content .card-profiles-row {
    row-gap: 18px;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .dash-content .titles-trending {
    padding-top: 40px !important;
    gap: 10px;
    align-items: flex-start !important;
  }
  .dash-content .trending-titles {
    padding-top: 10px !important;
  }
  .feed-type .feeds-post {
    flex-direction: column;
    padding: 10px 10px;
    gap: 5px !important;
  }
  .post-image {
    max-width: 100%;
    width: 100%;
  }
  .feed-type {
    min-height: 100%;
    max-height: 100%;
    height: 100%;
    overflow-y: hidden;
  }
  .profile-category .profile-heading {
    font-size: 21px;
  }
  .profile-heading {
    font-size: 24px;
  }
  .post-feeds {
    padding: 10px 16px;
  }
  .switch-profile {
    display: block;
  }
  .profilep-img {
    max-width: 50px;
    height: 50px;
  }
  .private-profile {
    color: var(--dark-grey);
    font-size: 17.5px !important;
    font-weight: 400 !important;
  }
  .profile-dropdown .owner-profile {
    gap: 0 !important;
  }
  .profile-name {
    margin-bottom: 90px !important;
  }
  .profile-dropdown {
    max-width: 150px;
  }
  .owner-name {
    font-size: 12px;
  }
  .settings-dropdown button {
    font-size: 12px;
  }
  .header .search-profile {
    display: none !important;
  }
  .desc-cover {
    font-size: 13px;
  }
  .option-svg {
    font-size: 26px;
  }
  .public-profile .status-field {
    flex-direction: column !important;
    gap: 0px !important;
  }
  .edit-image {
    min-height: 250px !important;
    max-height: 250px !important;
  }
  .public-profile .status-section {
    margin-top: -30px !important;
    margin-bottom: 14px;
  }
  .public-profile .status-section .status-wrap {
    gap: 10px !important;
    justify-content: space-around;
  }
  .status-heading {
    font-size: 15px;
  }
  .set-status {
    font-size: 13.5px;
  }
  .public-profile {
    padding: 0px 0px !important;
  }
  .profile-image-edit {
    max-width: 150px;
    height: 150px;
  }
  .public-profile .startup-points {
    row-gap: 14px;
    padding-bottom: 24px !important;
  }
  .startup-points .col-lg-6 {
    width: 100% !important;
  }
  .startup-name {
    row-gap: 14px;
  }
  .public-profile .profile-row {
    row-gap: 14px;
    padding-bottom: 16px !important;
  }
  .public-profile .updates-buttons {
    margin-top: 12px !important;
    margin-right: 0px !important ;
  }
  .public-profile .updates-buttons button {
    width: 50%;
  }
  .profile-image-edit .object-upload {
    padding-top: 30px !important;
  }
  .secondary-desc {
    font-size: 13px !important;
  }
  .startup-points .profile-heading {
    margin-top: 0px !important;
  }
  .startup-points .chat-desc {
    margin-bottom: 8px !important;
    font-size: 13px;
  }
  .public-profile.startup-points {
    padding-bottom: 26px !important;
  }
  .public-profile .form-investments {
    padding-bottom: 0px !important;
  }
  .form-address {
    min-height: 80px;
    height: 100%;
    max-height: 80px;
  }
  .form-investments .profile-row {
    padding-bottom: 0px !important;
  }
  .public-profile .profile-category {
    gap: 10px !important;
  }
  .profile-category .profile-heading {
    margin-bottom: 0 !important;
  }
  .profile-drag {
    margin-top: 24px !important;
    min-height: 250px;
  }
  .selected-category {
    max-width: 230px;
  }
  .trend-heading {
    margin-top: 10px !important;
  }
  .public-profile .profile-fields {
    padding-left: 10px !important;
    row-gap: 14px !important;
    margin-top: 14px !important;
  }
  .public-profile.public-footer {
    margin-top: 12px !important;
  }
  .selected-options .selected-option {
    margin-top: 8px !important;
  }
  .custom-dropdown button {
    padding: 0 !important;
  }
  .investment-table thead tr th:nth-child(1) {
    min-width: 153px;
  }
  .investment-table thead tr th:last-child {
    min-width: 120px;
  }
  .investment-table thead tr th {
    min-width: 150px;
    font-size: 13px !important;
    padding: 15px 11px 15px 11px;
  }
  .MuiTablePagination-selectLabel {
    margin-left: 15px !important;
  }
  .form-investments .profile-row .form-group {
    padding-top: 0px !important;
  }
  .cover-photo {
    margin-top: 70px !important;
    min-height: 250px !important;
    max-height: 250px !important;
  }
  .cover-photo img {
    min-height: 250px !important;
    max-height: 250px !important;
  }
  .footer-container {
    padding: 0px 0px !important;
  }
  .profile-image-startup {
    max-width: 150px;
    height: 150px;
    top: -65px;
  }
  .status-profile {
    width: 20px;
    height: 20px;
    border: 3px solid var(--white-color);
    right: 13px;
    top: 10px;
  }
  .brand-details {
    gap: 14px;
  }
  .profile-details-right {
    margin-top: -16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .profile-heading {
    font-size: 15px;
  }

  .profile-details-right .profile-status {
    margin: 0 !important;
  }
  .bordered-profiles .ratings-profile {
    margin-top: -20px !important;
    gap: 36px;
    flex-wrap: wrap;
    padding-left: 8px !important;
  }
  .social-icons {
    font-size: 18px;
  }
  .footer-container .private-box {
    padding: 20px;
    margin-top: 24px !important;
  }
  .private-heading {
    font-size: 16px;
  }
  .private-box .about-desc {
    font-size: 11.5px;
    margin-bottom: 5px !important;
  }
  .not-private-input {
    max-height: 100px;
    min-height: 100px;
    font-size: 14px;
  }
  .private-box .investor-btn {
    margin-top: 10px !important;
    max-width: 230px;
    font-size: 14px;
  }
  .footer-container .joined-bordered {
    margin-top: 24px !important;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 26px !important;
  }
  .joined-headings,
  .joined-desc {
    font-size: 14.5px;
  }
  .main-content .trending-heading-small {
    margin-top: 10px;
    font-size: 20px;
  }
  .joined-desc {
    border-radius: 12px;
    border: 1px solid var(--border-color);
    background-color: var(--bg-input);
    color: var(--dark-grey);
    padding: 12px 15px;
    display: flex;
    z-index: 0;
    margin-top: 5px;
  }
  .about-bordered .about-heading {
    font-size: 15px;
    margin-top: 5px !important;
  }
  .about-bordered .about-desc {
    font-size: 14px;
    margin-bottom: 0 !important;
  }
  .bordered-profiles .investor-pager {
    flex-wrap: wrap;
    gap: 8px !important;
    width: 100%;
    justify-content: end !important;
    margin-bottom: 14px;
    margin-top: 14px !important;
  }
  .bordered-profiles .investor-pager .view-button {
    width: 100%;
    max-width: 100%;
    font-size: 12px;
    padding: 10px 0px !important;
  }
  .interested-categories {
    max-width: 100%;
    width: 100%;
    margin-top: 10px;
  }
  .footer-container .bordered-profiles,
  .about-bordered {
    padding-bottom: 26px !important;
    margin-top: 20px !important;
  }
  .category-heading {
    font-size: 14.75px;
  }
  .category-item {
    font-size: 13px;
    padding: 8px 12px;
  }
  .investment-table tbody tr td {
    font-size: 12px;
    padding: 15px 11px 15px 11px;
  }
  .profile-links {
    display: none;
  }
  .joined-bordered .joined-table {
    gap: 6px !important;
  }
  .item-name {
    font-size: 13px;
  }
  .footer-container .profile-items-category {
    flex-direction: column !important;
    align-items: start !important;
    margin-top: 20px !important;
    gap: 6px;
  }
  .tabs-container {
    max-width: 100%;
    width: 100%;
  }
  .profile-items-category .profile-heading {
    margin-top: 10px !important;
  }
  .about-bordered.content {
    margin-top: 24px !important;
  }
  .tab {
    padding: 8px 10px;
    font-size: 13px;
    font-weight: 400;
  }
  .tab.active {
    padding: 8px 10px;
    font-size: 13px;
    font-weight: 400;
  }
  .investor-link {
    width: 100%;
    max-width: 85px;
  }
  .investor-pager .follow-btn {
    max-width: 80px;
    width: 100%;
    font-size: 12px;
    padding: 10px 0px;
  }
  .share-option {
    font-size: 22px;
    width: 22px;
  }
  .capture-btn {
    max-width: 143px !important;
    font-size: 12px !important;
    padding: 10px 0px !important;
  }
  .profile-grid {
    grid-gap: 8px;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 150px !important;
  }
  .about-feed {
    min-height: 120px;
    max-height: 120px;
  }
  .tag-icon {
    max-width: 18px;
  }
  .footer-container .news-section {
    flex-direction: row !important;
    gap: 10px;
    align-items: center !important;
  }
  .news-section .profile-heading {
    font-size: 20px !important;
  }
  .startup-feed {
    flex-direction: column !important;
    padding: 14px 14px !important;
  }
  .feeds-heading {
    font-size: 14px;
  }
  .read-btn {
    max-width: 100px;
    font-size: 12px;
  }
  .post-image img {
    min-height: 200px;
    max-height: 200px;
  }
  .feed-btn {
    padding: 10px 14px !important;
    font-size: 13px;
  }
  .private-input {
    min-height: 150px;
    font-size: 14px;
    max-height: 150px;
  }
  .private-box .primary-btn {
    max-width: 180px;
    width: 100%;
    font-size: 12px;
    margin-top: 6px !important;
  }
  .transparent-btn {
    max-width: 50%;
    width: 100%;
    font-size: 13px;
  }
  .dash-content .user-settings {
    padding: 28px 0px 0px 0px !important;
  }
  .user-settings-btn {
    max-width: 140px;
    width: 100%;
    padding: 10px 12px;
    font-size: 13px;
  }
  .edit-modal-investment .modal-content {
    min-width: auto;
    max-width: 100%;
  }
  .edit-modal-investment .modal-title .profile-heading {
    margin-top: 5px !important;
    margin-left: 10px !important;
    font-size: 22px;
  }
  .edit-modal-investment .modal-content {
    padding: 16px 10px !important;
  }
  .edit-modal-investment .modal-update {
    padding: 16px 10px 0px 10px;
  }
  .public-private {
    margin-bottom: 20px !important;
    width: 100%;
    margin-top: 20px;
  }
  /* TODO Status removed, should refer to edit profile pic, see EditProfile component */
  .status-field .status-craft {
    flex-direction: row !important;
    gap: 35px;
    align-items: flex-start !important;
    width: 100%;
  }
  .feed-img {
    min-height: 200px;
    height: 100%;
    max-width: 220px;
    width: 100%;
    min-height: 200px;
  }
  .Toastify__toast {
    max-width: 220px !important;
    width: 100%;
    margin-left: auto;
    margin-bottom: 12px;
  }
  .Toastify__toast-body > div:last-child {
    font-size: 12px;
  }
  .investment-row {
    gap: 10px;
  }
  .update-button {
    max-width: 100px !important;
    width: 100%;
    padding: 12px 0px !important;
  }
  .about-company {
    min-height: 80px;
    max-height: 80px;
  }
  .settings-dropdown .dropdown-menu {
    transform: translate(-8px, 23.25px) !important;
  }
  .cover-img img {
    min-height: 250px;
    max-height: 250px;
    height: 250px;
  }
  .user-settings .update-form {
    margin-top: 20px !important;
  }
  .update-form .update-pwd {
    margin-top: 8px;
    justify-content: space-between !important;
    gap: 10px !important;
  }
  .update-form .update-pwd .view-button {
    min-width: 47%;
  }
  .update-form .update-pwd .user-settings-btn {
    min-width: 47%;
  }
  .responsive-btn {
    font-size: 13px;
    padding: 8px 8px !important;
  }
  .column-pwd {
    padding-top: 12px !important;
  }
}
@media screen and (max-width: 412px) {
  .profile-dropdown {
    max-width: 135px;
  }
  .header {
    gap: 20px;
  }
  .angel-logos {
    max-width: 150px;
  }
  .public-profile .profile-category {
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 10px !important;
  }
  .selected-category {
    max-width: 100%;
  }
  .header .logo img {
    max-width: 140px;
  }
  .opener {
    font-size: 21px;
  }
}
